html {
  --bg-color: #ffffff;
  --bg-sec-color: #F5F4F8;

  --primary-color: #6C5FF3;

  --text-color: #292929;
  --text-sec-color: #808080;
  --text-white-color: #ffffff;

  --border-color: #e7e7e7;
}

.menu {
  &__content {
    display: flex;
    flex-direction: column;
    max-width: 668px;
    margin: 0 auto;
    .empty{
      color: var(--text-sec-color);
      width: 100vw;
      height: calc(100% - 100px);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
    }
  }
  .item {
    padding: 1em;
    border-top: 1px solid var(--border-color);
    display: flex;
    flex-direction: column;
    gap: 1em;
    &__head{
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2{
        font-size: 1rem;
      }
      span{
        color: var(--text-sec-color);
        font-size: .875rem;
      }
    }
    &__body{
      color: var(--text-sec-color)
    }
  }
}

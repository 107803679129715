.boardsList {
  padding: 1em;
  &__head{
    padding-bottom:2em;
  }
  &__body {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
}

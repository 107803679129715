.myInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
  label {
    color: var(--text-color-60);
    text-transform: uppercase;
    font-size: 0.625rem;
    &.error{
      color: red;
    }
  }
  input {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    padding: 0.75em;
    border: 1px solid var(--border-color);
    background-color: var(--bg-sec-color);
    &.error{
      border: 1px solid red;
      background-color: rgba($color: red, $alpha: .1);
    }
  }
  .empty{
    width: 100%;
    height: 40px;
    border-radius: 10px;
    padding: 0.75em;
    background-color: var(--bg-sec-color);
    font-size: 0.875rem;
  }
  textarea {
    width: 100%;
    height: 120px;
    border-radius: 10px;
    padding: 0.75em;
    border: 1px solid var(--border-color);
    background-color: var(--bg-sec-color);
  }
}
input[type="date"]{
  box-sizing: border-box;
  min-width: calc(100% - 1em);
  min-height: 40px;
}

.mySelect {
  &__control {
    background-color: var(--bg-sec-color) !important;
    border-radius: 10px !important;
    border-color: var(--border-color) !important;
  }
  &__value {
    &-container {
      max-height: 40px !important;
      display: flex !important;
      position: unset !important;
    }
  }
  &__single {
    &-value {
      font-family: "Roboto", sans-serif;
      font-size: 0.875rem;
    }
  }
  &__placeholder {
    font-family: "Roboto", sans-serif;
    font-size: 0.875rem;
    font-weight: 400 !important;
    color: var(--text-color-30) !important;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  &__indicator {
    &-separator {
      display: none !important;
    }
  }
}

.drop{
  width: 100%;
  height: 120px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid var(--border-color);
  background-color: var(--bg-sec-color);
  span{
    color: var(--text-color-60);
  }
  i{
    background-color: var(--text-color-60);
  }
}

.app {
  position: relative;
  height: calc(100 * var(--vh, 1vh));
  max-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  scroll-behavior: smooth;

  &.--ios {
    .topbar--mobile {
      padding-top: 42px;
      height: 102px;
    }
    .bottomBar {
      padding-bottom: 6px;
      height: 76px;
    }
    .navigationMobile {
      .icon--close {
        top: 4em;
      }
      .wrap {
        padding-top: 7em;
      }
    }
    .login__mobile {
      padding-top: 4em;
    }
  }
}

.loader__full{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img, svg{
    width: 30%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img{
    max-width: 100px;
    max-height: 100px;
  }

  svg{
    width: 40%;
    height: 40%;
    max-width: 130px;
    max-height: 130px;
  }
  span{
    font-size: 12px;
    font-weight: 600;
  }
}
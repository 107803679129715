.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1002;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;

  &__cover {
    z-index: 1001;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 300ms, backdrop-filter 300ms;

    &.active {
      background-color: rgba(0, 0, 0, 0.55);
      backdrop-filter: blur(2px);
    }

    &.closing {
      background-color: rgba(0, 0, 0, 0);
      backdrop-filter: blur(0);
      transition: background-color 100ms 200ms, backdrop-filter 100ms 200ms; // Delay for modal__content to close first
    }
  }
  &__content {
    position: absolute;
    top: 50%;
    z-index: 1002;
    width: 100%;
    max-width: calc(100% - 2em);
    max-height: calc(100% - 2em);
    display: flex;
    flex-direction: column;
    background-color: var(--bg-sec-color);
    min-height: 30vh;
    border-radius: 20px;
    margin: 1em;
    margin-top: 0;
    overflow: hidden;

    transform: translateY(-100%);
    transition: transform 200ms ease-out;

    &.active {
      transform: translateY(-50%);
    }

    &.closing {
      transform: translateY(-170%);
      transition: transform 300ms ease-out;
    }
    .modal__topSubtitle {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1rem;
      color: var(--text-color-67);
      font-weight: 300;
      font-size: 0.875rem;
    }

    .modal__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid var(--border-color);
      padding: 1em;
      h1 {
        position: absolute;
        width: calc(100% - 2em);
        font-size: 1rem;
        text-align: center;
      }
      .close {
        z-index: 1;
      }
    }

    .modal__body {
      flex-grow: 1;
      padding: 1em 0;
      overflow: auto;

      .center {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
        min-height: 68px;
      }
    }
    .modal__footer {
      display: flex;
      gap: 0.5em;
      .btn {
        white-space: nowrap;
      }
      .btn--primary {
        min-width: calc(70% - 0.25em);
        flex-grow: 1;
      }
      .btn--secondary {
        min-width: calc(30% - 0.25em);
        flex-grow: 1;
      }
      &.large {
        .btn--secondary,
        .btn--primary {
          min-width: unset;
        }
      }
    }
    @media (min-width: 424px) {
      &.large {
        max-width: 900px;
      }
      max-width: 380px;
    }
  }

  .modal__form {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .--minHeight {
    min-height: 145px;
  }
}

.galleryPopup {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0;
  width: 100vw;
  height: calc(100 * var(--vh, 1vh));
  background-color: black;
  color: white;
  z-index: 1000;
  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    &__head {
      z-index: 1001;
      width: 100%;
      position: fixed;
      top: 1em;
      padding: 0 1em;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .public {
        display: flex;
        align-items: center;
        gap: 0.5em;
      }
      span {
        font-size: 12px;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .iconWrap {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        background-color: #2f2f2f;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .icon--close {
        background-color: white;
        &:hover {
          cursor: pointer;
        }
      }
      .icon--trash {
        background-color: white;
      }
      .wrap {
        display: flex;
        gap: 8px;
        align-items: center;
        height: 32px;
        padding: 0 12px;
        border-radius: 16px;
        background-color: #2f2f2f;
        .icon {
          background-color: white;
        }
      }
      > div {
        display: flex;
        align-items: center;
        gap: 0.5em;
      }
    }
    &__body {
      max-height: calc(var(--vh) * 100);
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .image-wrapper {
        max-height: calc((var(--vh) * 100) - 70px - 18px - 2em);
        img {
          height: auto;
          max-height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }

    &__footer {
      padding-top: 2em;
      position: absolute;
      bottom: 0;
      z-index: 1;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 20%,
        rgba(0, 0, 0, 0) 100%
      );
      display: flex;
      flex-direction: column;
      max-width: 100%;
      width: 100%;
      .signature {
        padding: 0 1em;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 500;
        &.expanded {
          overflow: auto;
          white-space: unset;
        }
      }

      .wishes {
        padding: 0 1em;
        color: rgba(255, 255, 255, 0.67);
        display: flex;
        flex-direction: column;

        .wishesText {
          display: -webkit-box;
          -webkit-line-clamp: 2; /* Ogranicz do 2 linii */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;

          &.expanded {
            -webkit-line-clamp: unset; /* Usuń ograniczenie linii */
            overflow: visible;
          }
        }

        .readMore {
          cursor: pointer;
          color: white;
          font-size: 13px;
          margin-top: 0.5em;
        }
      }
      .commentsBar {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 45px;
        border-top: 1px solid rgba($color: #ffffff, $alpha: 0.2);
        margin-top: 1em;
        > div {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;
          font-size: 14px;
          i {
            background-color: white;
          }
          .icon--like-fill {
            background-color: red;
          }
          &:first-child {
            border-right: 1px solid rgba($color: #ffffff, $alpha: 0.2);
          }
        }
      }
    }
  }

  .image-gallery-thumbnails {
    width: 100%;
    padding: 0 1em;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 2em;
    .image-gallery-thumbnail-image {
      height: 50px;
      object-fit: cover;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .image-gallery-slides {
    height: 60vh;
    width: 100%;
    .image-gallery-slide {
      width: 100vw;
      height: 100%;
    }
    .image-gallery-image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-height: 60vh !important;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: #fff;
    visibility: visible;
    opacity: 1;
    font-size: 24px;
  }

  .swiper-button-next::after {
    content: "❯";
  }

  .swiper-button-prev::after {
    content: "❮";
  }

  $thumb-size-with-margin: 70px;
  $thumb-size: 50px;

  .column {
    flex-direction: column;
    justify-content: center;
    > .swiper:first-child {
      max-height: calc((var(--vh) * 100) - 70px - 18px - 3em);
    }
  }
  .swiper-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .swiper-slide div {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .gallery__swiper {
    height: calc(100% - $thumb-size-with-margin);
    width: 100%;
  }

  .thumbs__swiper {
    margin: 0 auto;
    padding: 10px 0;
    height: $thumb-size-with-margin;

    .swiper-slide {
      width: $thumb-size;
      height: 100%;
      opacity: 0.4;
      img {
        aspect-ratio: 1;
        &:hover {
          cursor: pointer;
        }
      }
    }

    .swiper-slide-thumb-active {
      opacity: 1;
    }
  }

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 1); /* Półprzezroczyste tło */
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    opacity: 1;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    visibility: visible;
    z-index: 1000; /* Sprawia, że loader będzie nad innymi elementami */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .icon {
      background-color: white;
    }
  }

  .loader i {
    margin-right: 8px;
    animation: spin 1s linear infinite; /* Dodaje animację ładowania do ikony */
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loader.action {
    opacity: 1; /* Loader jest widoczny */
    visibility: visible;
  }

  .loader:not(.action) {
    opacity: 0; /* Loader znika */
    visibility: hidden;
    transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out 1.2s; /* Opóźnienie 0.5s */
  }
}

.modal__multipleMedia {
  padding: 1em;
  padding-bottom: 0;
  .check {
    display: flex;
    background-color: var(--bg-color);
    min-height: 50px;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em 1em;
    border-radius: 10px;
    border: 1px solid transparent;
    font-size: 15px;
    .box {
      width: 32px;
      height: 32px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--bg-sec-color);
    }
    &.active {
      border-color: var(--primary-color);
      .box {
        background-color: var(--primary-color);
        i {
          background-color: var(--text-white-color);
        }
      }
    }
    &.sending {
      opacity: 0.5;
    }
  }
  .toSend {
    padding-top: 1em;
    padding-bottom: 1em;
    display: flex;
    flex-direction: column;
    gap: 4px;
    .desc {
      font-size: 13px;
    }
    > div {
      background-color: var(--bg-color);
      border-radius: 10px;
      border: 1px solid var(--border-color);
      padding: 1em;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 0.5em;
      column-gap: 0.5em;
      > div {
        position: relative;
        aspect-ratio: 1;
        img {
          width: 100% !important;
          height: 100% !important;
          object-fit: cover !important;
          border-radius: 5px;
        }
        .iconWrap {
          width: 15px;
          height: 15px;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          background-color: var(--primary-color);
          z-index: 1;
          i {
            background-color: var(--text-white-color);
            width: 15px;
            height: 15px;
            mask-size: 15px;
            -webkit-mask-size: 15px;
          }
          top: -5px;
          right: -5px;
        }
        .status {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: rgba($color: #000000, $alpha: 0.67);
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          &.hide {
            display: none;
          }
          > div {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .icon--pending {
              background-color: white;
              animation: spin 2s linear infinite;
            }
            .icon--check,
            .icon--close {
              background-color: white;
            }
          }
          @keyframes spin {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
        }
      }
      .add {
        background-color: var(--bg-sec-color);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: var(--text-sec-color);
        border: 1px solid var(--border-color);
      }
    }
  }
  .sendInfo {
    min-height: 60px;
    text-align: center;
    padding-bottom: 2em;
  }
  .footer {
    .btn {
      border-radius: 10px;
      box-shadow: none;
      &--border {
        color: var(--primary-color);
        border: 1px solid var(--primary-color);
      }
    }
    &--double {
      display: flex;
      gap: 12px;
      .btn {
        flex-grow: 1;
      }
    }
  }
}

.modal__sendWishes {
  padding-top: 1em;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;

  .input,
  .textarea,
  .photo {
    padding: 0 1em;
    input,
    textarea {
      width: 100%;
      height: 50px;
      border: 1px solid var(--border-color);
      border-radius: 10px;
      padding: 1em;
      resize: none;
      &::placeholder {
        color: var(--text-sec-color);
      }
    }
    textarea {
      height: 100px;
    }
    &.error {
      input,
      textarea {
        border: 1px solid red;
      }
      label {
        color: red;
        font-size: 12px;
      }
    }
  }

  .photo--error {
    color: red;
    font-size: 12px;
    padding-left: 16px;
  }

  .photo {
    margin: 0 1em;
    width: calc(100% - 2em);
    min-height: 50px;
    border: 1px solid var(--border-color);
    background-color: var(--bg-color);
    border-radius: 10px;
    padding: 1em;
    display: flex;
    align-items: center;
    gap: 1em;
    &.error {
      border: 1px solid red;
    }
  }

  .footer {
    padding: 0 1em;
    padding-top: 0.5em;
    .btn {
      border-radius: 10px;
      box-shadow: none;
    }
  }
}

.commentsModal {
  z-index: 999999;
  position: fixed;
  top: 0;
  background-color: var(--bg-color);
  width: 100%;
  height: 100%;
  min-height: calc(100 * var(--vh, 1vh));
  display: flex;
  flex-direction: column;
  &__topBar {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
    padding: 1em;
    .back {
      display: flex;
      align-items: center;
      gap: 4px;
      span {
        color: var(--text-sec-color);
      }
    }
  }
  &__body {
    flex-grow: 1;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    .item {
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
      width: 100%;
      padding: 1em;
      height: fit-content;
      span {
        color: var(--text-sec-color);
        font-size: 14px;
      }
      &:last-child {
        border: 0;
      }
    }
    .empty {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--text-sec-color);
      font-size: 12px;
    }
  }
  &__footer {
    border-top: 1px solid rgba($color: #000000, $alpha: 0.2);
    display: flex;
    flex-direction: column;
    padding: 1em;
    gap: 10px;
    .input {
      input {
        height: 40px;
        width: 100%;
        border-radius: 10px;
        background-color: var(--bg-sec-color);
        border: 1px solid rgba($color: #000000, $alpha: 0.1);
        padding-left: 1em;
        &::placeholder {
          color: var(--text-sec-color);
        }
      }
      &--withBtn {
        display: flex;
        gap: 10px;
        input {
          flex-grow: 1;
        }
        .btn {
          width: 40px;
          height: 40px;
          min-height: unset;
          border-radius: 10px;
          padding: 0;
          background-color: var(--primary-color);
          i {
            background-color: var(--text-white-color);
          }
        }
      }
    }
  }
}

.deleteBtn {
  background-color: #2f2f2f;
  flex-grow: 0 !important;
  padding: 0.6em 1em;
  border-radius: 10px;
  a {
    color: white;
    display: flex;
    align-items: center;
    gap: 4px;
    i {
      background-color: white;
    }
  }
}

.modalLink {
  padding: 0 1em;
  display: flex;
  flex-direction: column;

  h2 {
    text-align: center;
    font-size: 1rem;
  }

  .imgWrap{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 90%;
      height: 90%;
      object-fit: contain;
    }
    padding: 1em 0;
  }

  .partyCode{
    text-align: center;
    font-weight: 600;
    font-size: 1rem;
    .desc{
      font-weight: 400;
    }
    padding-bottom: 2em;
  }

  .or {
    width: 100%;
    text-align: center;
    color: var(--text-sec-color);
    padding: 1.5em 0;
  }
}

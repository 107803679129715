.gallery {
  &__content {
    max-width: 668px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 3px;
    row-gap: 3px;
    padding-top: 3px;
    .empty{
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      height: calc(100% - 70px);
      color: var(--text-sec-color);
      position: absolute;
    }
    .imgWrapper {
      aspect-ratio: 1;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .subtitle {
        position: absolute;
        bottom: 4px;
        right: 4px;
        span {
          color: var(--text-white-color);
          font-size: 12px;
          font-weight: 600;
        }
      }
      .amounts {
        position: absolute;
        width: auto;
        bottom: 4px;
        left: 4px;
        display: flex;
        align-items: center;
        gap: 8px;
        i {
          width: 14px;
          height: 14px;
          mask-size: 14px;
          -webkit-mask-size: 14px;
          background-color: white;
          position: relative;
          top: unset;
          left: unset;
          transform: unset;
        }
        .icon--comment {
          width: 13px;
          height: 13px;
          mask-size: 13px;
          -webkit-mask-size: 13px;
        }
        .icon--like-fill{
          background-color: red;
        }
        font-size: 12px;
        color: white;
        > div {
          display: flex;
          align-items: center;
          gap: 2px;
        }
      }
      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: var(--text-white-color);
      }
    }
  }
}

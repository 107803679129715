.home {
  max-height: calc(100 * var(--vh, 1vh));
  height: 100%;
  &__content {
    max-width: 668px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2em;
    padding: 1.5em;
    .btn--primary {
      width: 100%;
      height: 100px;
    }
    .or{
      font-size: 14px;
      color: var(--text-sec-color);
    }
    .input {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 45px;
      grid-template-rows: 1fr 45px;
      align-items: center;
      row-gap: 4px;
      column-gap: .5em;
      padding: 1em;
      background-color: var(--bg-sec-color);
      border-radius: 15px;
      label {
        grid-area: 1 / 1 / 2 / 3;
        font-size: 12px;
      }
      input {
        height: 100%;
        border: 1px solid var(--border-color);
        border-radius: 10px;
        padding: 1em;
        resize: none;
        &::placeholder {
          color: var(--text-sec-color);
        }
      }
      .btn {
        min-height: unset !important;
        height: 100%;
        box-shadow: unset;
        border-radius: 10px;
        background-color: var(--primary-color);
        i{
          background-color: white;
        }
      }
      &.error{
        label{
          color: red;
        }
        input{
          border: 1px solid red;
        }
      }
    }
  }
}

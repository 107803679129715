.schedule {
  &__content {
    display: flex;
    flex-direction: column;
    padding: 1em;
    max-width: 668px;
    margin: 0 auto;
  }
  .empty{
    color: var(--text-sec-color);
    width: 100vw;
    height: calc(100% - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
  }
  .item {
    height: 75px;
    display: flex;
    align-items: center;
    gap: 1em;
    &.active {
      .number .wrap {
        background-color: var(--primary-color);
        color: var(--text-white-color);
      }
    }
    span {
      color: var(--text-sec-color);
    }
    h2 {
      font-size: 1rem;
    }
    .number {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .wrap {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        background-color: var(--bg-sec-color);
        color: var(--text-color);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
      }
      &::before {
        content: "";
        display: flex;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1px;
        height: 100%;
        background-color: var(--bg-sec-color);
      }
    }
  }
}

.notfound {  
  max-height: calc(100 * var(--vh, 1vh));
  height: 100%;
  &__content {
    height: 100%;
    max-width: 668px;
    margin: 0 auto;
    padding: 1em;
    display: flex;
    flex-direction: column;
    .body {
      flex-grow: 1;
    }
    h1{
      padding-top: 2em;
      text-align: center;
      font-size: 2rem;
    }
    p{
      text-align: center;
      padding-top: 2em;
    }
  }
}

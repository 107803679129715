.btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 0;
  border-radius: 15px;
  min-height: 50px;
  font-weight: 400;
  user-select: none;
  font-size: 1rem;
  box-shadow: 0 4px 4px 0 rgba($color: #000000, $alpha: .1);
  &.disabled {
    opacity: 0.6;
  }
  &:hover {
    cursor: pointer;
    filter: brightness(0.98);
  }
  &:active {
    filter: brightness(1.02);
  }
}

.btn--primary {
  background-color: var(--primary-color);
  color: white;
  i {
    background-color: white;
  }
}

.btn--secondary {
  background-color: var(--bg-sec-color);
  color: var(--text-sec-color);
  i {
    background-color: var(--primary-color);
  }
}

.btn--white {
  background-color: var(--bg-color);
  color: var(--text-sec-color);
  i {
    background-color: var(--primary-color);
  }
}

.btn--sending{
  opacity: .5;
}


.add{
  &__content{
    min-height: calc(100 * var(--vh, 1vh) - 60px);
    max-width: 668px;
    margin: 0 auto;
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .btn{
      width: 100%;
      aspect-ratio: 2/1;
      span{
        text-align: center;
      }
      @media (max-width: 336px) {
        span{
          font-size: .875rem;
        }
      }
    }
    .or{
      padding: 2em 0;
      span{
        color: var(--text-sec-color);
      }
    }
  }
}
.qrScanner {
  width: 100%;
  height: 100%;
  .topbar{
    position: fixed;
  }
  > div {
    > div {
      top: 50% !important;
      translate: 0 -50% !important;
      height: unset !important;
      svg{
        border-color: transparent !important;
      }
    }
  }
  &__button{
    position: absolute;
    bottom: 2em;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    .btn--empty{
      color: white;
    }
  }
  @media (min-width: 1024px) {
    svg{
      opacity: 0;
    }
  }
  .topbar__wrap{
    top: unset!important;
    translate: unset !important;
  }
}

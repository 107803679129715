.alertWrap{
  display: flex;
  position: fixed;
  top: 35px;
  left: 0;
  right: 0;
  padding: 0 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
}
.alert {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em 0.7em;
  width: 400px;
  border: 1px solid;
  border-radius: 8px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.05);

  &__message {
    flex-grow: 1;
    font-size: 13px;
    font-weight: 500;
    color: white;
  }

  &__close{
    .icon{
      background-color: white;
    }
  }

  &.default {
    background-color: #E5E5E5;
    border-color: #434343;

    .alert__message{
      color: #434343;
    }
    .alert__close{
      .icon{
        background-color: #434343;
      }
    }
  }

  &.success {
    background-color: #E5FFED;
    border-color: green;

    .alert__message{
      color: green;
    }
    .alert__close{
      .icon{
        background-color: green;
      }
    }
  }

  &.error {
    background-color: #FFE5E5;
    border-color: red;

    .alert__message{
      color: red;
    }
    .alert__close{
      .icon{
        background-color: red;
      }
    }
  }

  @media (max-width: 1099px) {
    width: 100%;
  }

}

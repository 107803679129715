.dashboard__addProfile,
.dashboard__myProfiles,
.dashboard__saveProfiles,
.myProfiles__addProfile,
.myProfiles__content,
.savedProfiles__search,
.savedProfiles__content {
  margin: 0 auto;
  width: 100%;
  max-width: 1140px;
}

.manage__head > .wrap,
.profile__tabs__head > .wrap,
.profile__tabs__body,
.profile > .wrap{
  margin: 0 auto;
  width: 100%;
  max-width: 1140px;
}

.addProfile__steps,
.addProfile__content,
.addProfile__footer{
  margin: 0 auto;
  width: 100%;
  max-width: 1140px;
}


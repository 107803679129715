@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&family=Paytone+One&display=swap');

html {
  font-size: 16px;
  font-family: "Kumbh Sans", sans-serif;
}

h1 {
  color: var(--text-color);
  font-family: "Kumbh Sans", sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
}

h2 {
  color: var(--text-color);
  font-family: "Kumbh Sans", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
}

h3 {
  color: var(--text-color-67);
  font-family: "Kumbh Sans", sans-serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
}

h4 {
  color: var(--text-color-50);
  font-family: "Kumbh Sans", sans-serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
}
p {
  line-height: 1.8;
}

input {
  font-family: "Kumbh Sans", sans-serif;
  font-size: 0.875rem;
  &::placeholder {
    font-family: "Kumbh Sans", sans-serif;
    font-size: 0.875rem;
    color: var(--text-color-30);
  }
  &:focus {
    border: 1px solid var(--text-color);
  }
}

.biographyHtml {
  font-size: 0.875rem;
  > * {
    color: var(--text-color-60);
    min-height: 1em;
  }
}

textarea {
  &::placeholder {
    font-family: "Kumbh Sans", sans-serif;
    font-size: 0.875rem;
    color: var(--text-color-30);
  }
}

@mixin desktopFrame {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
}

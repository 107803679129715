.dashboard {
  max-width: 668px;
  margin: 0 auto;
  overflow-y: scroll;
  --ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  &__main {
    padding: 1em;
    padding-bottom: 0;
    min-height: calc(100 * var(--vh, 1vh));
    width: 100%;
    display: flex;
    flex-direction: column;
    .title {
      background-color: var(--bg-sec-color);
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 2em 0;
      padding-bottom: 1em;
      h1 {
        text-align: center;
        font-family: "Paytone One", sans-serif;
        color: var(--primary-color);
        font-size: 2rem;
        padding: 0 1em;
      }
      @media (max-width: 336px) {
        h1{
          font-size: 1.6rem;
          padding: 0 1em;
          &:last-child{
            font-size: 1.2rem;
          }
        }
      }
    }
    .content {
      padding: 1.5em;
      background-color: var(--bg-sec-color);
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      column-gap: 1.5em;
      row-gap: 1.5em;
      .btn {
        aspect-ratio: 1;
        max-width: 100%;
        width: 100%;
        height: 100%;
        @media (max-width: 336px) {
          span{
            font-size: .875rem;
          }
        }
      }
    }
    .showMore {
      padding-top: 1em;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 0.2em;
      flex-grow: 1;
      color: var(--text-sec-color);
      i{
        background-color:  var(--text-sec-color);
      }
    }
  }
  &__more{
    padding: 1em;
    padding-top: 1em;
    padding-bottom: 3em;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    .card{
      padding: 1.5em;
      display: flex;
      flex-direction: column;
      gap: .5em;
      align-items: center;
      background-color: var(--bg-sec-color);
      border-radius: 15px;
      h2, p, span{
        text-align: center;
        font-size: 1rem;
      }
      h2, span{
        font-weight: 700;
      }
      i{
        background-color: var(--primary-color);
      }
    }
    span{
      text-align: center;
    }
  }
  &__footer{
    border-top: 1px solid var(--border-color);
    width: 100%;
    background-color: var(--bg-sec-color);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1.5em;
    text-align: center;
    span{
      color: var(--text-sec-color);
      padding-top:1em ;
    }
  }
}
